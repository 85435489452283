<template>
  <div>
    <v-card max-width="650">
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold">Settings</span>
      </v-card-title>
      <v-card-text class="pa-5">
        <preloader v-if="status.getting" />
        
        <v-alert :value="status.error" type="error" border="left" dense>
          {{ status.error }}
        </v-alert>

        <v-form 
          v-if="!status.getting"
          ref="settingsForm"
          @submit.prevent="validateForm()"
        >
          <v-text-field
            v-model="data.notificationRecipientName"
            :rules="[rules.required]"
            label="Notifications Recipient Name"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="data.notificationRecipientEmail"
            :rules="[rules.required, rules.email]"
            label="Notifications Recipient Email"
            outlined
          ></v-text-field>

          <v-btn
            @click="validateForm()"
            :loading="status.saving"
            color="primary"
          >Save</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import rules from '@/rules'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.settings.data,
      status: state => state.settings.status,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('settings', [
      'getSettings',
      'saveSettings'
    ]),

    validateForm() {
      if (this.$refs.settingsForm.validate()) {
        this.saveSettings()
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getSettings()
  }
}
</script>